import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'
import scrollToElement from 'scroll-to-element'

import TESVG from './../../../component/TESVG'

import images from './../../../config/images'
import styles from './styles'

class Navbar extends Component {
	render() {
		return (
			<div style={styles.container}>
				<div style={styles.brandLogoWrapper}>
					<TESVG src={images.logo} style={styles.logo} />
				</div>
				<div style={styles.navContainer}>
					<ul style={styles.navUl}>
						<li style={styles.navLi}>
							<a
								onClick={(e) => {
									e.preventDefault()
									scrollToElement('#aboutSection')
								}}
								style={styles.link}
								href="/"
							>
								About
							</a>
						</li>
						<li style={styles.navLi}>
							<a
								onClick={(e) => {
									e.preventDefault()
									scrollToElement('#mdwcaresSection')
								}}
								style={styles.mdwCaresLink}
								href="/"
							>
								MDW Cares
							</a>
						</li>
						<li style={styles.navLi}>
							<a
								onClick={(e) => {
									e.preventDefault()
									scrollToElement('#storeSection')
								}}
								style={styles.link}
								href="/"
							>
								Store
							</a>
						</li>
						<li style={styles.navLi}>
							<a
								onClick={(e) => {
									e.preventDefault()
									scrollToElement('#postsSection')
								}}
								style={styles.link}
								href="/"
							>
								Posts
							</a>
						</li>
						<li style={styles.navLi}>
							<a
								onClick={(e) => {
									e.preventDefault()
									scrollToElement('#contactSection')
								}}
								style={styles.link}
								href="/"
							>
								Contact
							</a>
						</li>
						<li style={styles.navLi}>
							<a
								style={styles.link}
								href="https://medium.com/the-modern-domestic-woman"
								target="_blank"
								rel="noopener noreferrer"
							>
								Blog
							</a>
						</li>
					</ul>
				</div>
				<div style={styles.socialContainer}>
					<a
						href="https://www.facebook.com/TheModernDomesticWoman/"
						title="Facebook"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.facebookBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a>
					<a
						href="https://twitter.com/bethrago"
						title="Twitter"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.twitterBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a>
					<a
						href="https://www.pinterest.com/thecircularhome/pins/"
						title="Pintrest"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.pinterestBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a>
					<a
						href="https://www.instagram.com/elizabethrago/"
						title="Instagram"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.instagramBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a>
					{/* <a
						href="https://www.etsy.com/shop/ModernDomesticWoman"
						title="Etsy"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.etsyBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a> */}
					{/*<a
						href="https://medium.com/the-modern-domestic-woman"
						title="Medium"
						style={styles.socialLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TESVG
							src={images.social.mediumBlank}
							wrapperStyle={styles.socialIconWrapper}
							primaryAxis="x"
							style={styles.socialIcon}
						/>
					</a>*/}
				</div>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

export default Radium(Navbar)
