import { colors } from './../../../config/styles'
import images from './../../../config/images'

export default {
	container: {},
	header: {
		width: 'calc(100% - 60px)',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundImage: `url(${images.typewriterHomeHeader})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: 600,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: 30,
		paddingRight: 30,

		'@media (max-width: 1000px)': {
			height: 550,
		},
		'@media (max-width: 800px)': {
			height: 500,
		},
		'@media (max-width: 650px)': {
			height: 450,
			width: '100%',
		},
		'@media (max-width: 450px)': {
			height: 400,
		},
	},
	headerTitle: {
		fontSize: 60,
		color: colors.white,
		marginTop: 0,
		marginBottom: 0,
		textAlign: 'center',
		maxWidth: 700,
		marginLeft: 'auto',
		marginRight: 'auto',
		fontWeight: 300,
		lineHeight: 1.1,

		'@media (max-width: 1000px)': {
			fontSize: 54,
			maxWidth: 600,
		},
		'@media (max-width: 800px)': {
			fontSize: 46,
			maxWidth: 550,
		},
		'@media (max-width: 650px)': {
			fontSize: 34,
			maxWidth: 400,
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
			maxWidth: 300,
		},
	},
	headlineSection: {
		paddingTop: 80,
		paddingBottom: 50,
		paddingLeft: 30,
		paddingRight: 30,
	},
	headlineTitle: {
		fontSize: 50,
		marginTop: 0,
		marginBottom: 30,
		textAlign: 'center',
		maxWidth: 200,
		marginLeft: 'auto',
		marginRight: 'auto',
		lineHeight: 1.2,
		fontWeight: 300,

		'@media (max-width: 1200px)': {
			fontSize: 46,
		},
		'@media (max-width: 1000px)': {
			fontSize: 40,
		},
		'@media (max-width: 800px)': {
			fontSize: 36,
		},
		'@media (max-width: 650px)': {
			fontSize: 30,
			marginBottom: 20,
		},
		'@media (max-width: 450px)': {
			fontSize: 24,
		},
	},
	headlineCopy: {
		fontSize: 20,
		maxWidth: 525,
		marginLeft: 'auto',
		marginRight: 'auto',
		lineHeight: 1.75,
		textAlign: 'center',

		'@media (max-width: 1000px)': {
			fontSize: 18,
			maxWidth: 475,
		},
		'@media (max-width: 800px)': {
			fontSize: 16,
			maxWidth: 425,
		},
		'@media (max-width: 650px)': {
			fontSize: 14,
			maxWidth: 375,
		},
	},
	aboutTheAuthorSection: {
		paddingTop: 50,
		paddingBottom: 50,
		display: 'flex',
		alignItems: 'center',
		maxWidth: 1400,
		marginLeft: 'auto',
		marginRight: 'auto',

		'@media (max-width: 650px)': {
			flexWrap: 'wrap',
			paddingLeft: 30,
			paddingRight: 30,
		},
	},
	aboutTheAuthorCopyContainer: {
		width: '50%',
		paddingLeft: 160,
		paddingRight: 30,

		'@media (max-width: 1200px)': {
			paddingLeft: 140,
		},
		'@media (max-width: 1000px)': {
			paddingLeft: 80,
			paddingRight: 20,
		},
		'@media (max-width: 800px)': {
			paddingLeft: 30,
			paddingRight: 15,
		},
		'@media (max-width: 650px)': {
			paddingLeft: 0,
			paddingRight: 0,
			marginBottom: 15,
			width: '100%',
		},
	},
	aboutTheAuthorTitle: {
		fontSize: 46,
		marginTop: 0,
		marginBottom: 30,
		lineHeight: 1.2,
		fontWeight: 300,

		'@media (max-width: 1200px)': {
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			fontSize: 40,
		},
		'@media (max-width: 800px)': {
			fontSize: 36,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 38,
			textAlign: 'center',
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
		},
	},
	aboutTheAuthorCopy: {
		fontSize: 20,
		marginTop: 0,
		marginBottom: 30,
		lineHeight: 1.75,

		'@media (max-width: 1000px)': {
			fontSize: 18,
		},
		'@media (max-width: 800px)': {
			fontSize: 16,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 14,
			// textAlign: 'center',
		},
	},

	newsletterFormWrapper: {
		paddingTop: 30,

		'@media (max-width: 650px)': {
			paddingBottom: 30,
		},
	},
	newsletterForm: {
		'@media (max-width: 650px)': {
			textAlign: 'center',
			paddingLeft: 30,
			paddingRight: 30,
		},
	},
	newsletterLabel: {
		position: 'relative',
		display: 'block',
		width: 'auto',
		marginBottom: 3,
		fontWeight: 700,
		fontSize: 24,
		color: colors.darkGray,
		cursor: 'pointer',

		fontFamily: 'eloquent_jf_pro',

		'@media (max-width: 650px)': {
			fontSize: 20,
		},
	},
	newsletterLabelSubtitle: {
		display: 'inline-block',
		fontSize: 16,
		fontFamily: 'helvetica',
		lineHeight: 1.25,
		marginBottom: 10,
		fontWeight: 500,
		maxWidth: 325,
	},
	newsletterInput: {
		position: 'relative',
		display: 'block',
		width: '100%',
		fontSize: 14,
		backgroundColor: colors.white,
		color: colors.black,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 10,
		paddingLeft: 10,
		marginBottom: 10,

		borderTop: `1px solid ${colors.black}`,
		borderBottom: `1px solid ${colors.black}`,
		borderRight: `1px solid ${colors.black}`,
		borderLeft: `1px solid ${colors.black}`,
		transition: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

		':hover': {
			borderTop: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			boxShadow: `0 0 0 1px ${colors.black} inset`,
		},
		':active': {
			borderTop: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			boxShadow: `0 0 0 1px ${colors.black} inset`,
		},
	},
	newsletterSubmit: {
		width: 200,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 25,
		paddingLeft: 25,
		borderTop: `1px solid ${colors.black}`,
		borderRight: `1px solid ${colors.black}`,
		borderBottom: `1px solid ${colors.black}`,
		borderLeft: `1px solid ${colors.black}`,
		backgroundColor: colors.black,
		color: colors.white,
		display: 'inline-block',

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in',

		':hover': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},
		':active': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},

		'@media (max-width: 450px)': {
			width: '100%',
		},
	},

	aboutTheAuthorImageWrapper: {
		width: '50%',
		paddingLeft: 30,
		overflow: 'hidden',

		'@media (max-width: 1000px)': {
			paddingLeft: 20,
		},
		'@media (max-width: 800px)': {
			paddingLeft: 15,
		},
		'@media (max-width: 650px)': {
			width: '100%',
			paddingLeft: 0,
		},
	},
	aboutTheAuthorImage: {
		width: '150%',
		maxWidth: 1000,

		'@media (max-width: 650px)': {
			width: '100%',
		},
	},

	//MDW Cares
	mdwCaresParallax: {},
	mdwCaresParallaxTitle: {
		fontSize: 60,
		color: colors.white,
		marginTop: 0,
		marginBottom: 0,
		fontWeight: 300,
		maxWidth: 800,
		textAlign: 'center',
		lineHeight: 1.2,
		paddingTop: 225,
		paddingBottom: 225,
		marginLeft: 'auto',
		marginRight: 'auto',

		'@media (max-width: 1000px)': {
			fontSize: 52,
			maxWidth: 700,
			paddingTop: 200,
			paddingBottom: 200,
		},
		'@media (max-width: 800px)': {
			fontSize: 42,
			maxWidth: 575,
			paddingTop: 175,
			paddingBottom: 175,
		},
		'@media (max-width: 650px)': {
			fontSize: 34,
			maxWidth: 450,
			paddingTop: 150,
			paddingBottom: 150,
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
			maxWidth: 'none',
			paddingLeft: 30,
			paddingRight: 30,
			paddingTop: 125,
			paddingBottom: 125,
		},
	},
	mdwCares: {
		paddingTop: 50,
		paddingBottom: 100,
		maxWidth: 1200,
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		paddingRight: 30,

		'@media (max-width: 800px)': {
			paddingBottom: 75,
		},
		'@media (max-width: 650px)': {
			paddingBottom: 50,
			flexWrap: 'wrap',
		},
		'@media (max-width: 450px)': {
			paddingBottom: 25,
		},
	},
	mdwCaresLogoContainer: {
		width: '50%',
		paddingRight: 30,
		background: colors.white,

		'@media (max-width: 650px)': {
			width: '100%',
			paddingRight: 0,
			textAlign: 'center',
		},
	},
	mdwCaresImage: {
		width: '100%',

		'@media (max-width: 650px)': {
			width: '100%',
			marginLeft: 0,
			maxWidth: 400,
		},
	},
	mdwCaresCopyContainer: {
		width: '50%',
		paddingLeft: 30,

		'@media (max-width: 650px)': {
			width: '100%',
			paddingLeft: 0,
			textAlign: 'center',
		},
	},
	mdwCaresTitle: {
		fontSize: 46,
		marginTop: 0,
		lineHeight: 1.2,
		marginBottom: 0,

		'@media (max-width: 1200px)': {
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			fontSize: 40,
		},
		'@media (max-width: 800px)': {
			fontSize: 36,
		},
		'@media (max-width: 650px)': {
			fontSize: 38,
			textAlign: 'center',
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
		},
	},
	mdwCaresSubTitle: {
		fontSize: 30,
		marginBottom: 30,
		marginTop: 0,
		color: colors.peachyMango,

		'@media (max-width: 1200px)': {
			fontSize: 28,
		},
		'@media (max-width: 1000px)': {
			fontSize: 24,
		},
		'@media (max-width: 800px)': {
			fontSize: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 22,
			textAlign: 'center',
		},
		'@media (max-width: 450px)': {
			fontSize: 24,
		},
	},
	mdwCaresCopy: {
		fontSize: 20,
		marginTop: 0,
		marginBottom: 30,
		lineHeight: 1.75,
		maxWidth: 500,

		'@media (max-width: 1000px)': {
			fontSize: 18,
			maxWidth: 475,
		},
		'@media (max-width: 800px)': {
			fontSize: 16,
			maxWidth: 425,
		},
		'@media (max-width: 650px)': {
			fontSize: 14,
			maxWidth: 450,
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	mdwCaresLink: {
		backgroundColor: colors.peachyMango,
		color: colors.white,
		borderTop: `1px solid ${colors.peachyMango}`,
		borderRight: `1px solid ${colors.peachyMango}`,
		borderBottom: `1px solid ${colors.peachyMango}`,
		borderLeft: `1px solid ${colors.peachyMango}`,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 30,
		paddingLeft: 30,
		display: 'inline-block',
		textDecoration: 'none',

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in',

		':hover': {
			borderTop: `1px solid ${colors.peachyMango}`,
			borderRight: `1px solid ${colors.peachyMango}`,
			borderBottom: `1px solid ${colors.peachyMango}`,
			borderLeft: `1px solid ${colors.peachyMango}`,
			backgroundColor: colors.white,
			color: colors.peachyMango,
		},
		':active': {
			borderTop: `1px solid ${colors.peachyMango}`,
			borderRight: `1px solid ${colors.peachyMango}`,
			borderBottom: `1px solid ${colors.peachyMango}`,
			borderLeft: `1px solid ${colors.peachyMango}`,
			backgroundColor: colors.white,
			color: colors.peachyMango,
		},
	},
	theStore: {
		paddingTop: 50,
		paddingBottom: 100,
		maxWidth: 1200,
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		paddingRight: 30,

		'@media (max-width: 650px)': {
			flexWrap: 'wrap',
		},
	},
	theStoreBookContainer: {
		width: '50%',
		paddingRight: 30,

		'@media (max-width: 650px)': {
			width: '100%',
			paddingRight: 0,
			textAlign: 'center',
		},
	},
	onTenterHooksImage: {
		marginLeft: '-30%',
		width: '150%',

		'@media (max-width: 650px)': {
			width: '100%',
			marginLeft: 0,
			maxWidth: 400,
		},
	},
	theStoreCopyContainer: {
		width: '50%',
		paddingLeft: 30,

		'@media (max-width: 650px)': {
			width: '100%',
			paddingLeft: 0,
			textAlign: 'center',
		},
	},
	theStoreTitle: {
		fontSize: 46,
		marginTop: 0,
		marginBottom: 30,
		lineHeight: 1.2,

		'@media (max-width: 1200px)': {
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			fontSize: 40,
		},
		'@media (max-width: 800px)': {
			fontSize: 36,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 38,
			textAlign: 'center',
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
		},
	},
	theStoreCopy: {
		fontSize: 20,
		marginTop: 0,
		marginBottom: 30,
		lineHeight: 1.75,
		maxWidth: 500,

		'@media (max-width: 1000px)': {
			fontSize: 18,
			maxWidth: 475,
		},
		'@media (max-width: 800px)': {
			fontSize: 16,
			maxWidth: 425,
		},
		'@media (max-width: 650px)': {
			fontSize: 14,
			maxWidth: 450,
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	theStoreBuyNowLink: {
		backgroundColor: colors.black,
		color: colors.white,
		borderTop: `1px solid ${colors.black}`,
		borderRight: `1px solid ${colors.black}`,
		borderBottom: `1px solid ${colors.black}`,
		borderLeft: `1px solid ${colors.black}`,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 30,
		paddingLeft: 30,
		display: 'inline-block',
		textDecoration: 'none',

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in',

		':hover': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},
		':active': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},
	},

	testimonialSection: {
		paddinigTop: 50,
		paddingBottom: 80,
	},
	testimonialContent: {
		maxWidth: 1000,
		paddingLeft: 30,
		paddingRight: 30,

		marginLeft: 'auto',
		marginRight: 'auto',
	},
	testimonialTitle: {
		fontSize: 46,
		marginTop: 0,
		marginBottom: 30,
		textAlign: 'center',

		'@media (max-width: 1200px)': {
			marginTop: 0,
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			marginTop: 0,
			fontSize: 40,
		},
		'@media (max-width: 800px)': {
			marginTop: 0,
			fontSize: 36,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			marginTop: 0,
			fontSize: 38,
		},
		'@media (max-width: 450px)': {
			marginTop: 0,
			fontSize: 26,
		},
	},
	testimonialSlide: {
		paddingLeft: 30,
		paddingRight: 30,
		minHeight: 350,

		'@media (max-width: 1000px)': {
			minHeight: 375,
		},
		'@media (max-width: 800px)': {
			minHeight: 400,
		},
		'@media (max-width: 650px)': {
			minHeight: 450,
		},
		'@media (max-width: 450px)': {
			minHeight: 0,
		},
	},
	testimonialQuote: (big) => ({
		fontSize: big ? 28 : 20,
		lineHeight: 1.75,
		marginTop: 0,
		marginBottom: big ? 30 : 20,
		fontFamily: 'travelingtypewriter',

		'@media (max-width: 800px)': {
			fontSize: big ? 24 : 18,
		},
		'@media (max-width: 650px)': {
			fontSize: big ? 20 : 16,
		},
		'@media (max-width: 450px)': {
			fontSize: 14,
		},
	}),
	testimonialAuthor: {
		fontSize: 20,
		display: 'block',
		textAlign: 'right',
		fontFamily: 'travelingtypewriter',

		'@media (max-width: 800px)': {
			fontSize: 18,
		},
		'@media (max-width: 650px)': {
			fontSize: 16,
		},
		'@media (max-width: 450px)': {
			fontSize: 14,
		},
	},

	//
	// Perfections
	//
	perfectionSection: {},
	perfectionTitle: {
		fontSize: 60,
		color: colors.white,
		marginTop: 0,
		marginBottom: 0,
		fontWeight: 300,
		maxWidth: 800,
		textAlign: 'center',
		lineHeight: 1.2,
		paddingTop: 225,
		paddingBottom: 225,
		marginLeft: 'auto',
		marginRight: 'auto',

		'@media (max-width: 1000px)': {
			fontSize: 52,
			maxWidth: 700,
			paddingTop: 200,
			paddingBottom: 200,
		},
		'@media (max-width: 800px)': {
			fontSize: 42,
			maxWidth: 575,
			paddingTop: 175,
			paddingBottom: 175,
		},
		'@media (max-width: 650px)': {
			fontSize: 34,
			maxWidth: 450,
			paddingTop: 150,
			paddingBottom: 150,
		},
		'@media (max-width: 450px)': {
			fontSize: 26,
			maxWidth: 'none',
			paddingLeft: 30,
			paddingRight: 30,
			paddingTop: 125,
			paddingBottom: 125,
		},
	},

	//Feeds
	feedsSection: {
		paddingTop: 100,
		paddingBottom: 50,
		paddingLeft: 50,
		paddingRight: 50,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',

		'@media (max-width: 1000px)': {
			paddingLeft: 40,
			paddingRight: 40,
		},
		'@media (max-width: 800px)': {
			paddingLeft: 30,
			paddingRight: 30,
		},
		'@media (max-width: 650px)': {
			paddingLeft: 20,
			paddingRight: 20,
		},
	},
	feedsTitle: {
		fontSize: 46,
		marginTop: 0,
		marginBottom: 30,
		textAlign: 'center',
		width: '100%',

		'@media (max-width: 1200px)': {
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			fontSize: 38,
		},
		'@media (max-width: 800px)': {
			fontSize: 34,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 30,
		},
		'@media (max-width: 450px)': {
			fontSize: 24,
		},
	},
	mediumWrapper: {
		textDecoration: 'none',
		marginBottom: 50,
		width: '30%',

		'@media (max-width: 650px)': {
			width: '90%',
			marginBottom: 30,
		},
	},
	mediumThumbnail: {
		width: '100%',
		objectFit: 'cover',
		height: 250,
		marginBottom: 10,
	},
	mediumTitle: {
		color: colors.black,
		fontSize: 20,
		marginTop: 0,
		marginBottom: 10,
		fontWeight: 500,
		fontFamily: 'helvetica',

		'@media (max-width: 1000px)': {
			fontSize: 18,
		},
		'@media (max-width: 650px)': {
			fontSize: 16,
		},
		'@media (max-width: 450px)': {
			fontSize: 14,
		},
	},
	mediumDate: {
		color: colors.black,
		fontSize: 14,
		marginTop: 0,
		marginBottom: 0,
		textDecoration: 'none',
		fontWeight: 300,
		fontFamily: 'helvetica',
	},
	instagramWrapper: {
		position: 'relative',
		textDecoration: 'none',
		width: '30%',

		'@media (max-width: 650px)': {
			width: '90%',
			marginBottom: 30,
		},
	},
	instagramThumbnail: {
		width: '100%',
		objectFit: 'cover',
		height: 400,

		'@media (max-width: 1400px)': {
			height: 300,
		},
		'@media (max-width: 1000px)': {
			height: 200,
		},
		'@media (max-width: 650px)': {
			height: 'auto',
		},
	},
	instagramOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: 400,
		backgroundColor: 'rgba(0,0,0,0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		opacity: 0,

		transition: 'opacity 0.25s ease-in',
		':hover': {
			opacity: 1,
		},
		':active': {
			opacity: 1,
		},

		'@media (max-width: 1400px)': {
			height: 300,
		},
		'@media (max-width: 1000px)': {
			height: 200,
		},
		'@media (max-width: 650px)': {
			height: '100%',
		},
	},
	instagramIconWrapper: {
		width: 60,
		fill: colors.white,
		fontSize: 0,
	},
	instagramIcon: {
		width: '100%',
	},
	//Contact
	contactSection: {
		paddinTop: 50,
		paddingBottom: 50,
		paddingLeft: 30,
		paddingRight: 30,
	},
	contactTitle: {
		fontSize: 46,
		marginTop: 0,
		marginBottom: 30,
		textAlign: 'center',

		'@media (max-width: 1200px)': {
			fontSize: 44,
		},
		'@media (max-width: 1000px)': {
			fontSize: 38,
		},
		'@media (max-width: 800px)': {
			fontSize: 34,
			marginBottom: 20,
		},
		'@media (max-width: 650px)': {
			fontSize: 30,
		},
		'@media (max-width: 450px)': {
			fontSize: 24,
		},
	},
	contactForm: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: 600,
	},
	submitButton: {
		width: 200,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 25,
		paddingLeft: 25,
		borderTop: `1px solid ${colors.black}`,
		borderRight: `1px solid ${colors.black}`,
		borderBottom: `1px solid ${colors.black}`,
		borderLeft: `1px solid ${colors.black}`,
		backgroundColor: colors.black,
		color: colors.white,

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in',

		':hover': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},
		':active': {
			borderTop: `1px solid ${colors.black}`,
			borderRight: `1px solid ${colors.black}`,
			borderBottom: `1px solid ${colors.black}`,
			borderLeft: `1px solid ${colors.black}`,
			backgroundColor: colors.white,
			color: colors.black,
		},

		'@media (max-width: 450px)': {
			width: '100%',
		},
	},
}
