import { colors } from './../../../config/styles'

export default {
	input:{
		position		: 'relative',
		display			: 'block',
		width			: '100%',
		fontSize		: 14,
		paddingTop 		: 5,
		paddingBottom 	: 5,
		paddingRight 	: 5,
		paddingLeft 	: 5,
    	minHeight 		: 120,
		backgroundColor	: colors.white,
        color           : colors.black,

		border			: `1px solid ${colors.black}`,
		transition		: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

		':hover' : {
		    border 		: `1px solid ${colors.black}`,
		    boxShadow 	: `0 0 0 1px ${colors.black} inset`,
		},
		':active' : {
		    border 		: `1px solid ${colors.black}`,
		    boxShadow 	: `0 0 0 1px ${colors.black} inset`,
		}
	},
	disabled : {
		color 	: 'gray',
		border 	: `1px solid gray`,
	},
}
