import React, { Component } from 'react'
import {
	Route,
	withRouter,
	Switch,
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import {
	hideAlert,
	hideConfirm,
} from './../actions'

//import settings from './../config/settings'

//Utility
import NotFound from './../pages/NotFound'

//Global Components
import TEAlert from './../component/Popup/TEAlert'
import TEConfirm from './../component/Popup/TEConfirm'
import TENetworkActivity from './../component/Popup/TENetworkActivity'

//
//Front End
//
import FrontContentWrapper from './../layout/Front/FrontContentWrapper'
import Home from './../pages/Front/Home'

class App extends Component {
	 constructor(props) {
		super(props)

		this.handleConfirmClose		= this.handleConfirmClose.bind(this)
		this.handleAlertClose      	= this.handleAlertClose.bind(this)
	}

	//Alert
	handleConfirmClose() { this.props.hideConfirm() }
	handleAlertClose() { this.props.hideAlert() }

	render() {
		const {
			confirmTitle, confirmMessage, confirmIsVisible,
			confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
			alertIsVisible, alertTitle, alertMessage,
			networkActivityIsVisible, networkMessage,
			location
		} = this.props

		const locationArray = this.props.location.pathname.split('/')
		const locationKey = locationArray.slice(1,locationArray.length).join('/')

		return (
			<div>
				<div>
					<Switch>
						<Route path="/" render={() =>
							<FrontContentWrapper location={location}>
								<TransitionGroup>
									<CSSTransition key={locationKey} timeout={{ enter: 500, exit: 0 }} classNames="fade" appear>
										<div className="AnimationWrapper">
											<Switch key={location.key} location={location}>
												<Route exact path="/" 						component={Home} />

												<Route component={NotFound} />
											</Switch>
										</div>
									</CSSTransition>
								</TransitionGroup>
							</FrontContentWrapper>
						}/>
					</Switch>
				</div>


				<TEConfirm
					title={confirmTitle}
					message={confirmMessage}
					visible={confirmIsVisible}
					leftOnClick={this.handleConfirmClose}
					leftButtonTitle={confirmLeftTitle}
					rightOnClick={confirmRightOnClick}
					rightButtonTitle={confirmRightTitle}
				/>
				<TEAlert
					title={alertTitle}
					message={alertMessage}
					visible={alertIsVisible}
					onClick={this.handleAlertClose}
				/>
				<TENetworkActivity
					message={networkMessage}
					visible={networkActivityIsVisible}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const {
		confirmTitle, confirmMessage, confirmIsVisible,
		confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
		alertIsVisible, alertTitle, alertMessage,
		networkActivityIsVisible, networkMessage,
	} = state.Global


	return {
		confirmTitle, confirmMessage, confirmIsVisible,
		confirmLeftTitle, confirmRightOnClick, confirmRightTitle,
		alertIsVisible, alertTitle, alertMessage,
		networkActivityIsVisible, networkMessage,
	}
}

export default withRouter(connect(mapStateToProps, { hideAlert, hideConfirm })(App))
