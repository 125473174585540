import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import { Parallax } from 'react-parallax'
import ReactSVG from 'react-svg'
import moment from 'moment'
import Slider from 'react-slick'
import Typist from 'react-typist'

import { loadMediumFeed, loadInstagramFeed, sendContactEmail } from './../../../actions'
import { HomeKeys } from './../../../actions/types'

import TEHelmet from './../../../component/Header/TEHelmet'
import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit'

import images from './../../../config/images'
import styles from './styles'

class Home extends Component {
	state = { slide0TypingStart: true }
	componentDidMount() {
		const { loadMediumFeed, loadInstagramFeed } = this.props
		loadMediumFeed()
		loadInstagramFeed()
	}
	handleSubmit = (e) => {
		e.preventDefault()

		const {
			sendContactEmail,
			contactNameData,
			contactEmailData,
			contactSubjectData,
			contactMessageData,
		} = this.props

		const data = {
			contactNameData,
			contactEmailData,
			contactSubjectData,
			contactMessageData,
		}

		sendContactEmail(data)
	}
	render() {
		const meta = {
			title: 'The Modern Domestic Woman',
			description:
				'Elizabeth Rago brings to life a realistic conversation about being female in the world today with The Modern Domestic Woman.',
		}
		const {
			mediumFeed,
			instagramFeed,
			contactNameData,
			contactEmailData,
			contactSubjectData,
			contactMessageData,
		} = this.props
		const settings = {
			dots: true,
			infinite: true,
			arrows: false,
			speed: 1000,
			autoplaySpeed: 10000,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: (oldindex, newIndex) => {
				//This hack stops the slider from jerking back
				setTimeout(() => {
					this.setState({
						[`slide${newIndex}TypingStart`]: true,
					})
				}, 0)
			},
			// afterChange: (index) => {
			// 	for (let i = 0; i < testimonialArray.length; i++) {
			// 		const newState = {}
			// 		if (i !== index) {
			// 			newState[`slide${i}TypingStart`] = false
			// 			newState[`slide${index}TypingComplete`] = false
			// 		}
			// 		this.setState(newState)
			// 	}
			// },
		}
		const testimonialArray = [
			{
				quote:
					'Everything Elizabeth does with The Modern Domestic Woman is with authenticity - her transparency makes her relatable and REAL. What you see is what you get, in the best way possible! In a world of fakes and phonies on social media, portraying "perfection," Elizabeth gives an honest look into life, the good, the bad, and the ugly ... but mostly the good!',
				author: 'Katie McCall, St. Charles, Illinois',
			},
			{
				quote:
					"Elizabeth is the sharp, edgy friend that keeps you up to date on all the latest and coolest trends but is timeless in away that makes you feel like its all obtainable. I mean, who has time to raise a family, write a book, promote a book, have an awesome blog and be cool at the same time? The best part is that it's not full of B.S. photos looking perfect and glam all day. I mean, if you're going to be a domestic badass you should have your hair in a messy bun with no make-up at least a few times a week. She makes me believe I can do it too!",
				author: 'Meg Burns Lietz, St. Charles, Illinois',
			},
			{
				quote: 'Style, common sense, and humor. Love her writing!',
				author: 'Nicole Sponsler, St. Charles, Illinois',
			},
			{
				quote: 'I love the humor and how she keeps it real! A breath of fresh air!',
				author: 'Wendy Darlington, Chicago, Illinois',
			},
			{
				quote: 'Keep on keeping it real in this crazy, high expectations world!',
				author: 'Julie Arnold, Batavia, Illinois',
			},
		]

		return (
			<div style={styles.container}>
				<TEHelmet {...meta} />
				<div style={styles.header}>
					<h1 style={styles.headerTitle}>Supporting women. No matter what. </h1>
				</div>
				<div style={styles.headlineSection}>
					<h2 style={styles.headlineTitle}>Hello!</h2>
					<p style={styles.headlineCopy}>
						Are you seeking a safe, cozy, and non-judgmental place to gather with other
						like-minded women? You can find all that here at The Modern Domestic Woman.
					</p>
				</div>
				<div style={styles.aboutTheAuthorSection} id="aboutSection">
					<div style={styles.aboutTheAuthorCopyContainer}>
						<h3 style={styles.aboutTheAuthorTitle}>
							About
							<br />
							the Author
						</h3>
						<p style={styles.aboutTheAuthorCopy}>
							Syndicated columnist, Elizabeth Rago, brings to life a realistic
							conversation about being female in the world today with The Modern
							Domestic Woman. After spending time in the corporate and retail worlds,
							Elizabeth switched careers to freelance writing and found women's
							lifestyle content to be her cup of tea. Supporting women-owned
							businesses with meaty conversation stirring content, Elizabeth also
							spent two years emerged in the luxury design world (think living in a
							"Dream Home" Pinterest board).
						</p>
						<p style={styles.aboutTheAuthorCopy}>
							Now Elizabeth is ready to be unapologetically real, highlighting all the
							beautifully intricate details of being a woman, and welcoming the
							not-so-perfect elements too, with The Modern Domestic Woman.
						</p>
						{/*Mailchimp Form */}
						<div id="mc_embed_signup" style={styles.newsletterFormWrapper}>
							<form
								action="https://facebook.us16.list-manage.com/subscribe/post?u=56a9b83e7044c8eadaf4e8e43&amp;id=00722ede3b"
								method="post"
								id="mc-embedded-subscribe-form"
								name="mc-embedded-subscribe-form"
								className="validate"
								target="_blank"
								noValidate
								style={styles.newsletterForm}
								key="newsletterForm"
							>
								<label
									htmlFor="mce-EMAIL"
									style={styles.newsletterLabel}
									key="newsletterLabel"
								>
									Let's Keep In Touch
									<span style={styles.newsletterLabelSubtitle}>
										Enter your email for my regular newsletters, book updates
										and new blog posts.
									</span>
								</label>
								<input
									type="email"
									name="EMAIL"
									className="email"
									id="mce-EMAIL"
									placeholder="email address"
									required
									style={styles.newsletterInput}
									key="newsletterInput"
								/>
								<div
									style={{ position: 'absolute', left: -5000 }}
									aria-hidden="true"
								>
									<input
										type="text"
										name="b_56a9b83e7044c8eadaf4e8e43_00722ede3b"
										tabIndex="-1"
									/>
								</div>
								<TESubmit
									type="submit"
									name="subscribe"
									id="mc-embedded-subscribe"
									className="button"
									style={styles.newsletterSubmit}
									key="newsletterSubmit"
								>
									Subscribe
								</TESubmit>
							</form>
						</div>
					</div>
					<div style={styles.aboutTheAuthorImageWrapper}>
						<img
							src={images.homeAbout}
							alt="about the author"
							title="Elizabeth Rago"
							style={styles.aboutTheAuthorImage}
						/>
					</div>
				</div>
				<Parallax
					bgImage={images.mdwCaresParallax}
					bgImageAlt="mdw cares"
					strength={100}
					style={styles.mdwCaresParallax}
				>
					<h2 style={styles.mdwCaresParallaxTitle}>
						Introducing...
					</h2>
				</Parallax>
				<div style={styles.mdwCares} id="mdwcaresSection">
					<div style={styles.mdwCaresCopyContainer}>
						<h3 style={styles.mdwCaresTitle}>MDW Cares</h3>
						<h4 style={styles.mdwCaresSubTitle}>A New Support Resource</h4>
						<p style={styles.mdwCaresCopy}>
						Supporting women from all walks of life in this crazy high expectations world, 
						the Modern Domestic Woman (MDW) Cares works to provide a local directory of 
						practical resources for women in any capacity needed. From legal help and social 
						services like temporary housing and food to positive female communities, mental 
						health, and well-being organizations, MDW Cares is on a mission to round up a 
						list of services and support to get you started.
						</p>
						<a
							href="https://www.mdwcares.com"
							title="Visit MDW Cares"
							style={styles.mdwCaresLink}
							target="_blank"
							rel="noopener noreferrer"
							key="MDWCaresWebsite"
						>
							Visit MDW Cares Today!
						</a>
					</div>
					<div style={styles.mdwCaresLogoContainer}>
							<img
								src={images.mdwCaresLogo}
								alt="MDW Cares"
								title="MDW Cares"
								style={styles.mdwCaresImage}
							/>
					</div>
				</div>
				<div style={styles.theStore} id="storeSection">
					<div style={styles.theStoreBookContainer}>
						<img
							src={images.homeOnTenterHooks}
							alt="On Tenterhooks"
							title="On Tenterhooks"
							style={styles.onTenterHooksImage}
						/>
					</div>
					<div style={styles.theStoreCopyContainer}>
						<h3 style={styles.theStoreTitle}>On Tenterhooks</h3>
						<p style={styles.theStoreCopy}>
							A story of hope despite the hand you’re dealt, On Tenterhooks is the
							story of Lucy, a job-hopping and scattered young woman, and her best
							friend Claire, a brilliant entrepreneur and single mother. Their lives
							simultaneously come to a halt when they discover the diary of a young
							woman hidden in the walls of Lucy’s Chicago apartment.
						</p>
						<a
							href="https://www.amazon.com/Tenterhooks-Elizabeth-Rago/dp/198044868X/ref=sr_1_1?ie=UTF8&qid=1532879485&sr=8-1&keywords=on+tenterhooks"
							title="BUY NOW"
							style={styles.theStoreBuyNowLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							Buy Now
						</a>
					</div>
				</div>
				<section style={styles.testimonialSection}>
					<div style={styles.testimonialContent}>
						<h2 style={styles.testimonialTitle}>Testimonials</h2>
						<Slider {...settings}>
							{testimonialArray.map((testimonial, index) => {
								const { quote, author } = testimonial
								const quoteCharCount = quote.length
								const avgTypingDelay = 6000 / quoteCharCount

								return (
									<div key={index}>
										<div style={styles.testimonialSlide}>
											{this.state[`slide${index}TypingStart`] && (
												<Typist
													avgTypingDelay={
														avgTypingDelay > 80 ? 80 : avgTypingDelay
													}
													stdTypingDelay={0}
													onTypingDone={() =>
														this.setState({
															[`slide${index}TypingComplete`]: true,
														})
													}
													cursor={{ show: false }}
												>
													<p
														style={styles.testimonialQuote(
															quoteCharCount < 200
														)}
													>
														{quote}
													</p>
												</Typist>
											)}
											{this.state[`slide${index}TypingComplete`] && (
												<span style={styles.testimonialAuthor}>
													- {author}
												</span>
											)}
										</div>
									</div>
								)
							})}
						</Slider>
					</div>
				</section>
				<Parallax
					bgImage={images.homePerfection}
					bgImageAlt="perfection"
					strength={200}
					style={styles.perfectionSection}
				>
					<h2 style={styles.perfectionTitle}>
						Perfection is not the key to a happy domestic life.
					</h2>
				</Parallax>
				<div style={styles.feedsSection} id="postsSection">
					<h3 style={styles.feedsTitle}>Posts</h3>
					{mediumFeed.map((post, index) => {
						const { title, thumbnail, link, guid, pubDate } = post
						const dateString = moment(pubDate).format('MMM D YYYY')
						return (
							<a
								href={link}
								style={styles.mediumWrapper}
								key={guid}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={thumbnail}
									alt="medium-thumbnail"
									style={styles.mediumThumbnail}
								/>
								<h4 style={styles.mediumTitle}>{title}</h4>
								<h5 style={styles.mediumDate}>{dateString}</h5>
							</a>
						)
					})}
					{instagramFeed.map((post, index) => {
						const { id, link } = post
						const imageURL = post.images.standard_resolution.url
						return (
							<a
								href={link}
								style={styles.instagramWrapper}
								key={id}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={imageURL}
									alt="instagram-thumbnail"
									style={styles.instagramThumbnail}
								/>
								<div style={styles.instagramOverlay} key={`instaOverlay_${id}`}>
									<div style={styles.instagramIconWrapper}>
										<ReactSVG
											src={images.social.instagramBlank}
											style={styles.instagramIcon}
										/>
									</div>
								</div>
							</a>
						)
					})}
				</div>
				<div style={styles.contactSection} id="contactSection">
					<h3 style={styles.contactTitle}>Contact</h3>
					<TEForm onSubmit={this.handleSubmit} style={styles.contactForm}>
						<TEInputRow
							size="half"
							labelForKey="name"
							rowData={contactNameData}
							onChangeKey={HomeKeys.CONTACT_NAME_CHANGED}
							validationRules={['required']}
						/>
						<TEInputRow
							type="email"
							size="half"
							last
							labelForKey="email"
							rowData={contactEmailData}
							onChangeKey={HomeKeys.CONTACT_EMAIL_CHANGED}
							validationRules={['required']}
						/>
						<TEInputRow
							labelForKey="subject"
							rowData={contactSubjectData}
							onChangeKey={HomeKeys.CONTACT_SUBJECT_CHANGED}
							validationRules={['required']}
						/>
						<TEInputRow
							type="textarea"
							labelForKey="message"
							rowData={contactMessageData}
							onChangeKey={HomeKeys.CONTACT_MESSAGE_CHANGED}
							validationRules={['required']}
						/>
						<TESubmit style={styles.submitButton}>Submit</TESubmit>
					</TEForm>
				</div>
			</div>
		)
	}
}

Home.propTypes = {
	loadMediumFeed: PropTypes.func.isRequired,
	mediumFeed: PropTypes.array,
	loadInstagramFeed: PropTypes.func.isRequired,
	instagramFeed: PropTypes.array,
}

const mapStateToProps = (state) => {
	const {
		mediumFeed,
		instagramFeed,
		contactNameData,
		contactEmailData,
		contactSubjectData,
		contactMessageData,
	} = state.Home

	return {
		mediumFeed,
		instagramFeed,
		contactNameData,
		contactEmailData,
		contactSubjectData,
		contactMessageData,
	}
}

export default connect(
	mapStateToProps,
	{
		loadMediumFeed,
		loadInstagramFeed,
		sendContactEmail,
	}
)(Radium(Home))
