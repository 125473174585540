export default {
	container:{
		position		: 'relative',
		display			: 'inline-block',

		width			: '100%',
		marginBottom	: 15,

		textAlign		: 'left',
	}
}
