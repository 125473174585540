import { colors } from './../../../config/styles'

export default {
	container: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 20,
		paddingBottom: 20,
		height: 100,

		'@media (max-width: 800px)': {
			flexDirection: 'column',
			justifyContent: 'center',
			height: 'auto',
		},
	},
	//Logo
	brandLogoWrapper: {
		width: 125,
		fontSize: 0,
		flexShrink: 0,

		'@media (max-width: 800px)': {
			marginBottom: 20,
		},
	},
	logo: {
		width: '100%',
	},

	//Nav
	navContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-end',

		'@media (max-width: 800px)': {
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
		},
	},
	navUl: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'center',
		listStyle: 'none',
		padding: 0,
		margin: 0,
	},
	navLi: {
		position: 'relative',
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 500,
		paddingLeft: 20,
		paddingRight: 20,

		'@media (max-width: 1000px)': {
			paddingLeft: 15,
			paddingRight: 15,
		},
		'@media (max-width: 800px)': {
			paddingLeft: 10,
			paddingRight: 10,
		},
		'@media (max-width: 450px)': {
			paddingLeft: 6,
			paddingRight: 6,
			fontSize: 14,
		},
	},
	link: {
		textAlign: 'center',
		textDecoration: 'none',
		cursor: 'pointer',
		color: colors.black,
	},
	mdwCaresLink: {
		textAlign: 'center',
		textDecoration: 'none',
		cursor: 'pointer',
		color: colors.peachyMango
	},
	socialContainer: {
		display: 'flex',
		paddingLeft: 30,

		'@media (max-width: 1000px)': {
			paddingLeft: 15,
		},
		'@media (max-width: 800px)': {
			paddingLeft: 0,
		},
	},
	socialLink: {
		dispaly: 'inline-block',
		width: 30,
		fontSize: 0,
		padding: 0,
		borderRadius: '50%',
		border: `2px solid ${colors.black}`,
		marginLeft: 5,
		marginRight: 5,

		'@media (max-width: 1000px)': {
			width: 25,
		},
		'@media (max-width: 800px)': {
			width: 30,
		},
		'@media (max-width: 450px)': {
			width: 25,
			border: `1px solid ${colors.black}`,
		},
	},
	socialIcon: {
		fill: colors.black,
	},
}
