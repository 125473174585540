import {
	SHOW_ALERT,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	HomeKeys,
} from './../actions/types'
import axios from 'axios'
import React from 'react'

import TESimpleEmail from './../component/Email/TESimpleEmail'

import { validateInputData, formatEmailString, sendEmail } from './../helpers'

export const loadMediumFeed = () => {
	const { FETCH_MEDIUM_FEED } = HomeKeys

	return (dispatch) => {
		const axiosConfig = {
			method: 'GET',
			url: 'https://api.rss2json.com/v1/api.json',
			params: {
				rss_url: 'https://medium.com/feed/the-modern-domestic-woman',
				order_by: 'pubDate',
				count: 3,
				api_key: 'lokdolozant7whmnhdiplgegdbvxtambqz6dugug',
			},
		}
		axios(axiosConfig)
			.then((response) => {
				dispatch({
					type: FETCH_MEDIUM_FEED,
					payload: response.data.items,
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({
					type: FETCH_MEDIUM_FEED,
					payload: null,
				})
			})
	}
}

export const loadInstagramFeed = () => {
	const { FETCH_INSTAGRAM_FEED } = HomeKeys

	return (dispatch) => {
		const axiosConfig = {
			method: 'GET',
			url: `https://api.instagram.com/v1/users/self/media/recent/`,
			params: {
				access_token: '4531169520.881eb10.05e02761b0b24d68a09acde502df5a01',
				count: 3,
			},
		}
		axios(axiosConfig)
			.then((response) => {
				// console.log(response.data.data)
				dispatch({
					type: FETCH_INSTAGRAM_FEED,
					payload: response.data.data,
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({
					type: FETCH_INSTAGRAM_FEED,
					payload: null,
				})
			})
	}
}

export const sendContactEmail = (data) => {
	const { SEND_CONTACT_EMAIl_SUCCESS, SEND_CONTACT_EMAIl_FAIL } = HomeKeys

	const { contactNameData, contactEmailData, contactSubjectData, contactMessageData } = data

	if (!contactNameData.valid) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: validateInputData(contactNameData),
			})
		}
	}
	if (!contactEmailData.valid) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: validateInputData(contactEmailData),
			})
		}
	}
	if (!contactSubjectData.valid) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: validateInputData(contactSubjectData),
			})
		}
	}
	if (!contactMessageData.valid) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: validateInputData(contactMessageData),
			})
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Sending Email...',
		})

		//
		// Send Email
		//
		const emailSubject = contactSubjectData.value
		const emailHTMLString = formatEmailString(
			<TESimpleEmail
				title="The Modern Domestic Women Contact Form"
				body={
					<span style={{ whiteSpace: 'pre-line' }}>
						Name: {contactNameData.value}
						<br />
						<br />
						Email: {contactEmailData.value}
						<br />
						<br />
						Message: {contactMessageData.value}
					</span>
				}
			/>
		)
		const emailString = `Name: ${contactNameData.value}\r\n\r\nEmail: ${
			contactEmailData.value
		}\r\n\r\nMessage: ${contactMessageData.value}`

		const data = {
			bccEmailAddresses: [],
			ccEmailAdresses: [],
			toEmailAddresses: ['elizabetharago@gmail.com'],
			// toEmailAddresses: ['stuart.casarotto@38thstreetstudios.com'],
			subjectdata: emailSubject,
			bodyData: emailString,
			htmlData: emailHTMLString,
			bodyCharset: 'UTF-8',
			subjestCharset: 'UTF-8',
			sourceEmail: 'contactForm@themoderndomesticwoman.com',
			replyToAddresses: [contactEmailData.value],
		}
		sendEmail(data)
			.then((response) => {
				console.log(response)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({ type: SEND_CONTACT_EMAIl_SUCCESS })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'We greatly appreciate you reaching out.',
					},
				})
			})
			.catch((error) => {
				console.log('Error Sending Code Email', error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({ type: SEND_CONTACT_EMAIl_FAIL })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error Sending Email.',
					},
				})
			})
	}
}
