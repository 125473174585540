const images = {
	logo					: require('./../images/logo.svg'),

	navIcon					: require('./../images/navIcon.svg'),

	homeHeader 				: require('./../images/homeHeader.jpg'),
	typewriterHomeHeader	: require('./../images/typewriterHomeHeader.jpg'),
	homeAbout 				: require('./../images/homeAbout.jpg'),
	homeOnTenterHooks 		: require('./../images/onThenterhooks.png'),
	homePerfection 			: require('./../images/homePerfection.jpg'),
	mdwCaresLogo			: require('./../images/mdwCaresLogo.png'),
	mdwCaresParallax		: require('./../images/mdwCaresParallax.jpg'),

	social : {
		facebook 				: require('./../images/social/facebook.svg'),
		linkedIn 				: require('./../images/social/linkedIn.svg'),
		twitter 				: require('./../images/social/twitter.svg'),
		medium 					: require('./../images/social/medium.svg'),
		youtube 				: require('./../images/social/youTube.svg'),
		website 				: require('./../images/social/website.svg'),

		facebookBlank 			: require('./../images/social/facebookBlank.svg'),
		linkedInBlank 			: require('./../images/social/linkedInBlank.svg'),
		twitterBlank 			: require('./../images/social/twitterBlank.svg'),
		instagramBlank 			: require('./../images/social/instagramBlank.svg'),
		mediumBlank 			: require('./../images/social/mediumBlank.svg'),
		youtubeBlank 			: require('./../images/social/youtubeBlank.svg'),
		behanceBlank 			: require('./../images/social/behanceBlank.svg'),
		pinterestBlank 			: require('./../images/social/pinterestBlank.svg'),
		etsyBlank 				: require('./../images/social/etsyBlank.svg'),
	}
};

export default images;
