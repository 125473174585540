import { colors } from './../../../config/styles';

export default {
	container: {
		paddingTop			: 30,
		paddingLeft			: 30,
		paddingRight		: 30,
		paddingBottom		: 30,
		textAlign 			: 'center',
	},
	footerLink: {
		textAlign 			: 'center',
		color 				: colors.black,
		marginTop 			: 0,
		marginBottom 		: 15,
		lineHeight 			: 1.5,
		fontSize 			: 14,
	},
	copyright: {
		marginTop 			: 0,
		marginBottom 		: 0,
		lineHeight 			: 1.5,
		fontSize 			: 14,
	},
}
