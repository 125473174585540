import { colors } from './../../../config/styles'

export default {
    label:{
        position        : 'relative',
        display         : 'block',
        width           : 'auto',
        marginBottom    : 3,
        fontWeight      : 700,
        fontSize        : 16,
        color           : colors.darkGray,
        cursor          : 'pointer',
    },
    disabled : {
        cursor          : 'not-allowed',
        color           : colors.gray,
    },
    noHtmlFor : {
        cursor          : 'default',
    }
}
