//
//TE Version 0.1.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { inputHasChanged } from './../../../actions'

import TERow from './../TERow'
import TELabel from './../TELabel'
import TEInput from './../TEInput'
import TETextarea from './../TETextarea'

//import styles from './styles'

class TEInputRow extends Component {
	constructor(props) {
		super(props)

		this.state = { firstDataValidation : false }

		this.handleInputChange 							= this.handleInputChange.bind(this)
	}
	componentDidMount() {
		//Validate Data On Load
		const { inputHasChanged, rowData, onChangeKey, validationRules, confirmValue } = this.props
		inputHasChanged( onChangeKey, validationRules, rowData.value, confirmValue )
	}
	componentWillReceiveProps(nextProps){
		if (!this.state.firstDataValidation){
			if (nextProps && this.props){
				if (nextProps.rowData && this.props.rowData){
					if (!this.state.firstDataValidation){
						if (nextProps.rowData.value !== this.props.rowData.value){
							this.setState({ firstDataValidation: true })
							const { inputHasChanged, rowData, onChangeKey, validationRules, confirmValue } = nextProps
							inputHasChanged( onChangeKey, validationRules, rowData.value, confirmValue )
						}
					}
				}
			}
		}
	}
	handleInputChange(event){
		const value = event.target.value

		const { inputHasChanged, onChangeKey, validationRules, confirmValue } = this.props

		inputHasChanged( onChangeKey, validationRules, value, confirmValue )
	}

	render() {
		const {
			//Row
			rowStyles,
			size,
			last,

			//Label
			labelStyles,
			labelTitle,

			//Input
			inputStyles,
			type,
			onChange,
			disabled,
			inputExtras,
			validationRules = [],

			//Both
			rowData,
			labelForKey,

		} = this.props

		const { 
			title,
			value,		
			validLabel,	
			valid,		
  		} = rowData

  		const inputData = { value, validLabel, valid }

		return (
			<TERow size={size} last={last} style={rowStyles}>
				<TELabel 
					htmlFor={labelForKey}
					labelText={labelTitle || title}
					style={labelStyles}
					required={validationRules.includes('required')}
					disabled={disabled}
				/>
				{type !== 'textarea' ?
					<TEInput
						type={type}
						inputData={inputData}
						onChange={onChange || this.handleInputChange}
						id={labelForKey}
						disabled={disabled}
						style={inputStyles}
						{...inputExtras}
					/>
					:
					<TETextarea
						inputData={inputData}
						onChange={onChange || this.handleInputChange}
						id={labelForKey}
						disabled={disabled}
						style={inputStyles}
						{...inputExtras}
					/>
				}
			</TERow>
		)
	}
}

TEInputRow.propTypes = {
	//Row
	rowStyles		: PropTypes.object,
	size 			: PropTypes.string,
	last 			: PropTypes.bool,

	//Input
	inputStyles		: PropTypes.object,
	type 			: PropTypes.string,
	onChange 		: PropTypes.func,
	onChangeKey		: PropTypes.string,
	disabled		: PropTypes.bool,

	//Both
	rowData : PropTypes.shape({
		title		: PropTypes.string,
		value		: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.bool,
		]),
		validLabel	: PropTypes.string.isRequired,
		valid		: PropTypes.bool.isRequired,
	}),
	labelForKey		: PropTypes.string,
}

TEInputRow.defaultProps = {}

const mapStateToProps = (state) => {
	return { }
}

export default connect(mapStateToProps, { inputHasChanged })(Radium(TEInputRow))
