import { colors } from './../../../config/styles'

export default {
	input:{
		position		: 'relative',
		display			: 'block',
		width			: '100%',
		fontSize		: 14,
		backgroundColor	: colors.white,
		color           : colors.black,
		paddingTop 		: 10,
		paddingBottom 	: 10,
		paddingRight 	: 10,
		paddingLeft 	: 10,

		borderTop 		: `1px solid ${colors.black}`,
		borderBottom 	: `1px solid ${colors.black}`,
		borderRight 	: `1px solid ${colors.black}`,
		borderLeft 		: `1px solid ${colors.black}`,
		transition		: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

		':hover': {
			borderTop 		: `1px solid ${colors.black}`,
			borderBottom 	: `1px solid ${colors.black}`,
			borderRight 	: `1px solid ${colors.black}`,
			borderLeft 		: `1px solid ${colors.black}`,
			boxShadow 		: `0 0 0 1px ${colors.black} inset`,
		},
		':active': {
			borderTop 		: `1px solid ${colors.black}`,
			borderBottom 	: `1px solid ${colors.black}`,
			borderRight 	: `1px solid ${colors.black}`,
			borderLeft 		: `1px solid ${colors.black}`,
			boxShadow 		: `0 0 0 1px ${colors.black} inset`,
		}
	},
	disabled : {
		color 	: 'gray',
		border 	: `1px solid greay`,
	},
}
