import React, { Component } from 'react'
import Radium from 'radium'
//import ReactSVG from 'react-svg'

//import images from './../../../config/images'

import styles from './styles'

class Footer extends Component {
	render() {
		return (
			<div style={styles.container}>
				<a href='https://www.38thstreetstudios.com/' title='Carefully Crafted By 38th Street Studios' style={styles.footerLink}>Carefully Crafted By 38th Street Studios</a>
				<p style={styles.copyright}>© {new Date().getFullYear()} - Elizabeth Rago - ALL RIGHTS RESERVED</p>
			</div>
		)
	}
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Radium(Footer)
