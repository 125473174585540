export const settings = {
	dev: process.env.NODE_ENV === 'development',

	GOOGLE_ANALYTICS_KEY	: 'UA-116101398-1',
	EMAIL_API_ROUTE			: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	DEVELOPMENT : {
		DEBUG					: true,

		BASE_URL				: 'http://localhost:3007',
	},
	PRODUCTION : {
		DEBUG 					: false,

		BASE_URL				: 'hhttps://www.themoderndomesticwoman.com/',
	}
}

export default settings
